<template>
  <td>
    {{(data.Height  / 1) | number}}
    <span class="has-text-grey-mid-light">x</span>
    {{ data.Width | number }}
    <span class="has-text-grey-mid-light">x</span>
    {{ data.Depth | number }}
    <span class="has-text-grey-mid-light">m</span>
  </td>
</template>

<script>
export default {
  props: ["data", "t", "truck_job"],
};
</script>

<style scoped></style>
